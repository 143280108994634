const INCREASE_DROPPEDELEMENT: 'INCREASE_DROPPEDELEMENT' =
  'INCREASE_DROPPEDELEMENT';

const DELETE_ITEM: 'DELETE_ITEM' = 'DELETE_ITEM';
const SET_DROPDIRECTION: 'SET_DROPDIRECTION' = 'SET_DROPDIRECTION';
const SET_DRAGGEDCONSTRUCTOR: 'SET_DRAGGEDCONSTRUCTOR' =
  'SET_DRAGGEDCONSTRUCTOR';
const GOUP_POSITION: 'GOUP_POSITION' = 'GOUP_POSITION';

const GODOWN_POSITION: 'GODOWN_POSITION' = 'GODOWN_POSITION';

const CLEAR_STUFFINGLIST: 'CLEAR_STUFFINGLIST' = 'CLEAR_STUFFINGLIST';

export {
  INCREASE_DROPPEDELEMENT,
  DELETE_ITEM,
  SET_DROPDIRECTION,
  SET_DRAGGEDCONSTRUCTOR,
  GOUP_POSITION,
  GODOWN_POSITION,
  CLEAR_STUFFINGLIST,
};
