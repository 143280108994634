const SET_ALLINGREDIENTS_REQUEST: 'SET_ALLINGREDIENTS_REQUEST' =
  'SET_ALLINGREDIENTS_REQUEST';
const SET_ALLINGREDIENTS_SUCCESS: 'SET_ALLINGREDIENTS_SUCCESS' =
  'SET_ALLINGREDIENTS_SUCCESS';
const SET_ALLINGREDIENTS_FAILURE: 'SET_ALLINGREDIENTS_FAILURE' =
  'SET_ALLINGREDIENTS_FAILURE';
const SET_INGREDIENTSBYCAT: 'SET_INGREDIENTSBYCAT' = 'SET_INGREDIENTSBYCAT';
const REPLACE_BUN: 'REPLACE_BUN' = 'REPLACE_BUN';
const SET_CURRENT: 'SET_CURRENT' = 'SET_CURRENT';
const REMOVE_CURRENT: 'SET_CURRENT' = 'SET_CURRENT';
const SET_MODALINGREDIENTS: 'SET_MODALINGREDIENTS' = 'SET_MODALINGREDIENTS';
const REMOVE_MODALINGREDIENTS: 'REMOVE_MODALINGREDIENTS' =
  'REMOVE_MODALINGREDIENTS';
const CLEAR_BUN: 'CLEAR_BUN' = 'CLEAR_BUN';

export {
  SET_ALLINGREDIENTS_REQUEST,
  SET_ALLINGREDIENTS_SUCCESS,
  SET_ALLINGREDIENTS_FAILURE,
  SET_INGREDIENTSBYCAT,
  REPLACE_BUN,
  SET_CURRENT,
  REMOVE_CURRENT,
  SET_MODALINGREDIENTS,
  REMOVE_MODALINGREDIENTS,
  CLEAR_BUN,
};
