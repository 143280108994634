const SET_TOTALSUM: 'SET_TOTALSUM' = 'SET_TOTALSUM';

const GET_ORDERDATA_REQUEST: 'GET_ORDERDATA_REQUEST' = 'GET_ORDERDATA_REQUEST';
const GET_ORDERDATA_SUCCESS: 'GET_ORDERDATA_SUCCESS' = 'GET_ORDERDATA_SUCCESS';
const GET_ORDERDATA_FAILURE: 'GET_ORDERDATA_FAILURE' = 'GET_ORDERDATA_FAILURE';
const CLEAR_ORDERDATA: 'CLEAR_ORDERDATA' = 'CLEAR_ORDERDATA';

const SET_POSITIONSDATA: 'SET_POSITIONSDATA' = 'SET_POSITIONSDATA';

export {
  GET_ORDERDATA_SUCCESS,
  GET_ORDERDATA_REQUEST,
  GET_ORDERDATA_FAILURE,
  SET_TOTALSUM,
  CLEAR_ORDERDATA,
  SET_POSITIONSDATA,
};
