const REGISTER_REQUEST: 'REGISTER_REQUEST' = 'REGISTER_REQUEST';
const REGISTER_SUCCESS: 'REGISTER_SUCCESS' = 'REGISTER_SUCCESS';
const REGISTER_FAILURE: 'REGISTER_FAILURE' = 'REGISTER_FAILURE';

const RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST' =
  'RESET_PASSWORD_REQUEST';

const RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS' =
  'RESET_PASSWORD_SUCCESS';

const LOGIN_SUCCESS: 'LOGIN_SUCCESS' = 'LOGIN_SUCCESS';
const LOGIN_REQUEST: 'LOGIN_REQUEST' = 'LOGIN_REQUEST';

const LOGIN_FAILURE: 'LOGIN_FAILURE' = 'LOGIN_FAILURE';

const GET_USER: 'GET_USER' = 'GET_USER';

const SET_LOGGED: 'SET_LOGGED' = 'SET_LOGGED';

const SET_LOGGEDOUT: 'SET_LOGGEDOUT' = 'SET_LOGGEDOUT';

const SHOW_APIMESSAGE: 'SHOW_APIMESSAGE' = 'SHOW_APIMESSAGE';
const CLEAR_APIMESSAGE: 'CLEAR_APIMESSAGE' = 'CLEAR_APIMESSAGE';
const GET_USER_REQUEST: 'GET_USER_REQUEST' = 'GET_USER_REQUEST';
const SET_ACTOKEN: 'SET_ACTOKEN' = 'SET_ACTOKEN';

export {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  GET_USER,
  SET_LOGGED,
  SET_LOGGEDOUT,
  SHOW_APIMESSAGE,
  CLEAR_APIMESSAGE,
  GET_USER_REQUEST,
  SET_ACTOKEN,
};
