const SET_IFMOBILE: 'SET_IFMOBILE' = 'SET_IFMOBILE';
const SET_IFMOBILEORDERED: 'SET_IFMOBILEORDERED' = 'SET_IFMOBILEORDERED';

const SET_WINDOWDATA: 'SET_WINDOWDATA' = 'SET_WINDOWDATA';

const OPEN_MOBILEMENU: 'OPEN_MOBILEMENU' = 'OPEN_MOBILEMENU';

const CLOSE_MOBILEMENU: 'CLOSE_MOBILEMENU' = 'CLOSE_MOBILEMENU';

const UNFOLD_SUBMOBILEMENU: 'UNFOLD_SUBMOBILEMENU' = 'UNFOLD_SUBMOBILEMENU';

const FOLD_SUBMOBILEMENU: 'FOLD_SUBMOBILEMENU' = 'FOLD_SUBMOBILEMENU';
const SET_TOUCHEDITEM: 'SET_TOUCHEDITEM' = 'SET_TOUCHEDITEM';
const CLEAR_TOUCHEDITEM: 'CLEAR_TOUCHEDITEM' = 'CLEAR_TOUCHEDITEM';

const SET_OFFSETS: 'SET_OFFSETS' = 'SET_OFFSETS';
const SET_INITIALS: 'SET_INITIALS' = 'SET_INITIALS';
const SET_FINALS: 'SET_FINALS' = 'SET_FINALS';
const CLEAR_INITIALS: 'CLEAR_INITIALS' = 'CLEAR_INITIALS';
const CLEAR_FINALS: 'CLEAR_FINALS' = 'CLEAR_FINALS';

export {
  SET_IFMOBILE,
  SET_IFMOBILEORDERED,
  SET_WINDOWDATA,
  OPEN_MOBILEMENU,
  CLOSE_MOBILEMENU,
  UNFOLD_SUBMOBILEMENU,
  FOLD_SUBMOBILEMENU,
  SET_TOUCHEDITEM,
  CLEAR_TOUCHEDITEM,
  SET_OFFSETS,
  SET_INITIALS,
  SET_FINALS,
  CLEAR_INITIALS,
  CLEAR_FINALS,
};
